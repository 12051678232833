export const environment = {
  production: true,
  apiUrlBsV1: "https://api.poshvine.com/bs/v1/",
  apiUrlCsV1: "https://api.poshvine.com/cs/v1/",
  apiUrlPaV1: "https://api.poshvine.com/pa/v1/",
  apiUrlPsV1: "https://api.poshvine.com/ps/v1/",
  apiUrlBsInternal: "https://api.poshvine.com/bs/internal/",
  healthWellnessId: "60a5c359-05fc-4b39-8f91-dd49713dfcb7",
  clientId: "d81abc85-678c-44a8-9df2-aeef0e1d588c",
  projectId: "93d2d3f4-27a1-4f58-85e9-441280ede495",
  fitTv: {
    basicVideoUrl: "https://www.fitternity.com/fit-tv/",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDY3MjgzNTEsInN1YiI6ImZpdHRlcm5pdHkiLCJuYW1lIjoicG9zaHZpbmUiLCJlbnYiOiJwcm9kdWN0aW9uIn0.EZzlwDaYBZQnEdfnFHljVs8IbD9ntfrfX6fmu8mxLv0",
  },
  SENTRY_ORG: "rzp",
  SENTRY_PROJECT: "axis-aura",
  SENTRY_AUTH_TOKEN:
    "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",
  sentryDsn:
    "https://78d18d2a586297a6d6b51d56ed64d09b@o515678.ingest.us.sentry.io/4507372701614080",
};
